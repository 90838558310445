import {defineStore} from 'pinia'
import {AxiosError, AxiosResponse} from 'axios';
import ISimpleUser from '@/models/user/ISimpleUser'
import useMainStore from './main.store';
import jwtInterceptor from '@/helpers/http';
import IStudentAssignment from '@/models/courses/StudentAssignment/IStudentAssignment';
import UpdateCourseAssignment from '@/models/courses/StudentAssignment/UpdateCourseAssignment';
import ILessonResult from '@/models/courses/StudentAssignment/ILessonResult';
import IUpdateLessonResult from '@/models/courses/StudentAssignment/IUpdateLessonResult';
import ISimpleCourse from '@/models/courses/Course/ISimpleCourse';
import IAddStudentAssignment from '@/models/courses/StudentAssignment/IAddStudentAssignment';
import IOrganizationAssignment from '@/models/courses/StudentAssignment/IOrganizationAssignment';
import IAssignmentPreferences from '@/models/courses/StudentAssignment/IAssignmentPreferences';
import IUpdateAssignmentPreferences from '@/models/courses/StudentAssignment/IUpdateAssignmentPreferences';
import INewAssignmentPreferences from '@/models/courses/StudentAssignment/INewAssignmentPreferences';
import IGridFilter from '@/models/search/IGridFilter';
import IAddNewHireAssignment from '@/models/courses/StudentAssignment/NewHire/IAddNewHireAssignment';
import INewHireCourseAssignment from '@/models/courses/StudentAssignment/NewHire/INewHireCourseAssignment';
import IUpdateNewHireAssignmentOrganization from '@/models/courses/StudentAssignment/NewHire/IUpdateNewHireAssignmentOrganization';
import IUpdateNewHireAssignmentDetails from '@/models/courses/StudentAssignment/NewHire/IUpdateNewHireAssignmentDetails';
import IAnnualAssignmentSearchResult from '@/models/search/Assignments/IAnnualAssignmentSearchResult';
import IAnnualCourseAssignment from '@/models/courses/StudentAssignment/Annual/IAnnualCourseAssignment';
import IUpdateAnnualAssignmentOrganization from '@/models/courses/StudentAssignment/Annual/IUpdateAnnualAssignmentOrganization';
import IUpdateAnnualAssignmentDetails from '@/models/courses/StudentAssignment/Annual/IUpdateAnnualAssignmentDetails';
import IAddAnnualAssignment from '@/models/courses/StudentAssignment/Annual/IAddAnnualAssignment';
import IRollingAssignmentSearchResult from '@/models/search/Assignments/IRollingAssignmentSearchResult';
import IRollingCourseAssignment from '@/models/courses/StudentAssignment/Rolling/IRollingCourseAssignment';
import INewHireAssignmentSearchResult from '@/models/search/Assignments/INewHireAssignmentSearchResult';
import IUpdateRollingAssignmentOrganization from '@/models/courses/StudentAssignment/Rolling/IUpdateRollingAssignmentOrganization';
import IUpdateRollingAssignmentCourse from '@/models/courses/StudentAssignment/Rolling/IUpdateRollingAssignmentCourse';
import IUpdateRollingAssignmentDetails from '@/models/courses/StudentAssignment/Rolling/IUpdateRollingAssignmentDetails';
import IAddRollingAssignment from '@/models/courses/StudentAssignment/Rolling/IAddRollingAssignment';
import IUpdateNewHireAssignmentCourse from '@/models/courses/StudentAssignment/NewHire/IUpdateNewHireAssignmentCourse';
import IUpdateAnnualAssignmentCourse from '@/models/courses/StudentAssignment/Annual/IUpdateAnnualAssignmentCourse';
import ISimpleLessonAssignment from '@/models/courses/Lesson/ISimpleLessonAssignment';
import {formatServerErrorResponse} from '@/helpers/jwtHelper';
import {computed, ComputedRef, Ref} from 'vue';
import IAssignmentForStudentView from '@/models/courses/StudentAssignment/IAssignmentForStudentView';
import {useSessionStorage} from '@vueuse/core';
import SearchCourse from '@/models/search/courses/SearchCourse';
import CourseAssignmentState from '@/models/courses/StudentAssignment/CourseAssignmentState';
import {AssignmentMethod} from '@/models/courses/StudentAssignment/AssignmentMethod';
import StudentAssignmentMethod from '@/models/courses/StudentAssignment/StudentAssignmentMethod';
import {getOrganizationLevelName} from '@/helpers/organizationHelper';
import {OrganizationLevel} from '@/models/user/OrganizationLevel/OrganizationLevel';
import DropStudentAssignment from '@/models/courses/StudentAssignment/DropAssignment/DropStudentAssignment';
import DropOrganizationAssignment from '@/models/courses/StudentAssignment/DropAssignment/DropOrganizationAssignment';
import DropOrganizationWideAssignment from '@/models/courses/StudentAssignment/DropAssignment/DropOrganizationWideAssignment';

export interface ICourseAssignmentStore {
    displayNamesOfAssignedStudents: ComputedRef<string>,
    displayNamesOfAssignedCourses: ComputedRef<string>,
    getAllCourses: ComputedRef<SearchCourse[]>,
    getEnableDate: ComputedRef<Date | undefined>,
    getTargetDate: ComputedRef<Date | undefined>,
    getDropDate: ComputedRef<Date | undefined>,
    getCourseAssignmentState: ComputedRef<CourseAssignmentState>,
    getSelectedOrganizationDisplayString: ComputedRef<string | undefined>,

    getStudentDisplayNameById(userId: string): string,
    getCourseNameById(courseId: number): string,
    setIndividualStudents(users: ISimpleUser[]): void,
    setOrganizationStudents(orgAssignment: StudentAssignmentMethod): void,
    setCourses(courses: SearchCourse[]): void,
    setAssignedDates(enableCourse: Date | undefined, target: Date | undefined, dropCourse: Date | undefined): void,
    removeStudent(user: ISimpleUser): void,
    removeOrganizationAssignment(): void,
    removeCourse(course: ISimpleCourse): void,
    addStudentAssignments(studentAssignments: IAddStudentAssignment[]): Promise<void>,
    addAssignmentToOrganization(organizationAssignments: IOrganizationAssignment[]): Promise<void>,
    getAssignmentsForStudent(userProfileId: number): Promise<IStudentAssignment[]>,
    getAssignmentsForLoggedInStudent(): Promise<IAssignmentForStudentView[]>,
    getCourseAssignment(studentId: number, courseId: number): Promise<IStudentAssignment>,
    getLessonResult(studentId: number, courseId: number, lessonId: number): Promise<ILessonResult>,
    getLessonAssignments(studentId: number, courseId: number): Promise<ISimpleLessonAssignment[]>,
    updateCourseAssignment(courseAssignment: UpdateCourseAssignment): Promise<void>,
    updateLessonResult(lessonResult: IUpdateLessonResult): Promise<void>,
    getCourseDeleteStatus(studentId: number, courseId: number): Promise<boolean>,
    dropAssignment(studentId: number, courseId: number): Promise<void>,
    deleteAssignment(studentId: number, courseId: number): Promise<void>,
    validateCourseAssignments(studentAssignments: IAddStudentAssignment[]):  Promise<IAddStudentAssignment[]>
    validateOrganizationAssignments(organizationAssignments: IOrganizationAssignment[]):  Promise<IOrganizationAssignment[]>
    getAssignedCoursesForStudents(studentIds: string[]): Promise<ISimpleCourse[]>,
    getAssignedCoursesForOrganization(organizationLevel: OrganizationLevel, organizationId: number): Promise<ISimpleCourse[]>,
    getAssignedCoursesOrganizationWide(organizationLevel: OrganizationLevel, organizationId: number, orgWideName: string): Promise<ISimpleCourse[]>,
    getStudentAssignmentDropCount(studentDropMdl: DropStudentAssignment): Promise<number>,
    getOrganizationAssignmentDropCount(organizationDropMdl: DropOrganizationAssignment): Promise<number>,
    getOrganizationWideAssignmentDropCount(organizationWideDropMdl: DropOrganizationWideAssignment): Promise<number>,
    dropAssignmentForIndividuals(studentDropMdl: DropStudentAssignment): Promise<void>,
    dropAssignmentForOrganization(organizationDropMdl: DropOrganizationAssignment): Promise<void>,
    dropAssignmentOrganizationWide(organizationWideDropMdl: DropOrganizationWideAssignment): Promise<void>,
    getAssignmentPreferences(divisionId: number): Promise<IAssignmentPreferences[]>,
    updateAssignmentPreferences(assignmentPreferences: IUpdateAssignmentPreferences): Promise<void>,
    addAssignmentPreferences(assignmentPreferences: INewAssignmentPreferences): Promise<void>,
    searchNewHireAssignments(filter: IGridFilter): Promise<INewHireAssignmentSearchResult>,
    addNewHireAssignment(newHireAssignment: IAddNewHireAssignment): Promise<void>,
    getNewHireAssignment(newHireAssignmentId: number): Promise<INewHireCourseAssignment>,
    deleteNewHireAssignment(newHireAssignmentId: number): Promise<void>,
    updateNewHireAssignmentOrganizations(updateNewHireOrgs: IUpdateNewHireAssignmentOrganization): Promise<void>,
    updateNewHireAssignmentCourse(updateCourse: IUpdateNewHireAssignmentCourse): Promise<void>,
    updateNewHireCourseAssignmentDetails(updateDetails: IUpdateNewHireAssignmentDetails): Promise<void>,
    searchAnnualAssignments(filter: IGridFilter): Promise<IAnnualAssignmentSearchResult>,
    getAnnualAssignment(annualAssignmentId: number): Promise<IAnnualCourseAssignment>,
    deleteAnnualAssignment(annualAssignmentId: number): Promise<void>,
    updateAnnualAssignmentOrganizations(updateAnnualOrgs: IUpdateAnnualAssignmentOrganization): Promise<void>,
    updateAnnualAssignmentCourse(updateCourse: IUpdateAnnualAssignmentCourse): Promise<void>,
    updateAnnualCourseAssignmentDetails(updateDetails: IUpdateAnnualAssignmentDetails): Promise<void>,
    addAnnualAssignment(annualAssignment: IAddAnnualAssignment): Promise<void>,
    searchRollingAssignments(filter: IGridFilter): Promise<IRollingAssignmentSearchResult>,
    getRollingAssignment(rollingAssignmentId: number): Promise<IRollingCourseAssignment>,
    deleteRollingAssignment(rollingAssignmentId: number): Promise<void>,
    updateRollingAssignmentOrganizations(updateRollingOrgs: IUpdateRollingAssignmentOrganization): Promise<void>,
    updateRollingAssignmentCourse(updateCourse: IUpdateRollingAssignmentCourse): Promise<void>,
    updateRollingCourseAssignmentDetails(updateDetails: IUpdateRollingAssignmentDetails): Promise<void>,
    addRollingAssignment(rollingAssignment: IAddRollingAssignment): Promise<void>,
    $reset(): void
}

const useCourseAssignmentStore = defineStore('courseAssignmentStore', (): ICourseAssignmentStore => {
    const mainStore = useMainStore();

    const assignmentState: Ref<CourseAssignmentState> = useSessionStorage('courseAssignmentStore_assignmentState', { selectedCourses: [] });

    const getCourseAssignmentState: ComputedRef<CourseAssignmentState> = computed<CourseAssignmentState>(() => assignmentState.value);
    const getAllCourses: ComputedRef<SearchCourse[]> = computed<SearchCourse[]>(() => assignmentState.value.selectedCourses);
    const getEnableDate: ComputedRef<Date | undefined> = computed<Date | undefined>(() => assignmentState.value?.enableCourseOnDate);
    const getTargetDate: ComputedRef<Date | undefined> = computed<Date | undefined>(() => assignmentState.value?.targetDate);
    const getDropDate: ComputedRef<Date | undefined> = computed<Date | undefined>(() => assignmentState.value?.dropCourseDate);

    const displayNamesOfAssignedStudents: ComputedRef<string> = computed<string>(() => {
        if (assignmentState.value.studentAssignment?.assignmentMethod !== AssignmentMethod.Individual)
            return 'No Students Selected';

        if (!assignmentState.value.studentAssignment.students?.length)
            return 'No Students Selected';

      return assignmentState.value.studentAssignment.students.map(u => `${u.firstName} ${u.lastName}`).join(', ');
    });

    const displayNamesOfAssignedCourses: ComputedRef<string> = computed<string>(() => {
        if (!assignmentState.value?.studentAssignment?.students)
            return 'No Students Selected';

        return assignmentState.value.selectedCourses.map(c => c.name).join(', ');
    });

    const getSelectedOrganizationDisplayString: ComputedRef<string | undefined> = computed<string | undefined>(() => {
        const studentAssignmentState = assignmentState.value.studentAssignment;

        if (!studentAssignmentState || studentAssignmentState.assignmentMethod === AssignmentMethod.Individual)
            return undefined;

        if (studentAssignmentState?.assignmentMethod === AssignmentMethod.OrganizationWide)
            return `All ${studentAssignmentState.organizationWideName} Departments in ${studentAssignmentState.organizationName} ${getOrganizationLevelName(studentAssignmentState.organizationLevel)}`;

        if (studentAssignmentState?.assignmentMethod === AssignmentMethod.Organization)
            return `${studentAssignmentState.organizationName} ${getOrganizationLevelName(studentAssignmentState.organizationLevel)}`;

        return undefined;
    });

    function getStudentDisplayNameById(identityId: string): string {
        if (assignmentState.value.studentAssignment?.assignmentMethod !== AssignmentMethod.Individual)
            return 'No Students Selected';

        if (!assignmentState.value?.studentAssignment.students)
            return 'No Students Selected';

        return assignmentState.value?.studentAssignment.students
            .filter(student => student.userId === identityId)
            .map(s => `${s.firstName} ${s.lastName}`)[0]
    }

    function getCourseNameById(courseId: number): string {
        if (!assignmentState.value || !assignmentState.value.selectedCourses.length)
            return 'No Courses Selected';

        const course = assignmentState.value
            .selectedCourses
            .find(course => course.courseId === courseId);

        return course?.name || 'Course Not Found';
    }

    function setIndividualStudents(users: ISimpleUser[]): void {
        assignmentState.value.studentAssignment = {
            assignmentMethod: AssignmentMethod.Individual,
            students: users
        };
    }

    function setOrganizationStudents(orgAssignment: StudentAssignmentMethod): void {
        assignmentState.value.studentAssignment = orgAssignment;
    }

    function setCourses(courses: SearchCourse[]): void {
        if (!assignmentState.value)
            return mainStore.setErrorMsg('Unable to set courses');

        assignmentState.value.selectedCourses = courses;
    }

    function setAssignedDates(enableCourse: Date | undefined, target: Date | undefined, dropCourse: Date | undefined): void {
        if (!assignmentState.value)
            return mainStore.setErrorMsg('Unable to set dates');

        assignmentState.value.enableCourseOnDate = enableCourse;
        assignmentState.value.targetDate = target;
        assignmentState.value.dropCourseDate = dropCourse;
    }

    function removeStudent(user: ISimpleUser): void {
        const removeIndex = assignmentState.value.studentAssignment?.students?.findIndex?.(s => s.userId === user.userId);

        if (removeIndex === undefined || removeIndex === -1)
            return mainStore.setErrorMsg('unable to find user to remove');

        assignmentState.value.studentAssignment?.students?.splice?.(removeIndex, 1);
    }

    function removeOrganizationAssignment(): void {
        assignmentState.value.studentAssignment = undefined;
    }

    function removeCourse(course: ISimpleCourse): void {
        if (!assignmentState.value)
            return mainStore.setErrorMsg('Unable to remove course');

        const removeIndex = assignmentState.value.selectedCourses.findIndex(c => c.courseId === course.courseId);

        if (removeIndex === -1) {
            return mainStore.setErrorMsg('unable to find course to remove');
        }

        assignmentState.value.selectedCourses.splice(removeIndex, 1);
    }

    async function addStudentAssignments(studentAssignments: IAddStudentAssignment[]): Promise<void> {
        const actionName = 'addStudentAssignments';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/bulk-add', studentAssignments);
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to add Student Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function addAssignmentToOrganization(organizationAssignments: IOrganizationAssignment[]): Promise<void> {
        const actionName = 'addAssignmentToOrganization';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/assign-to-org', organizationAssignments);

            mainStore.setInfoMsg('Successfully Added Student Assignments')
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Assign Student Assignment to Organization', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAssignmentsForStudent(userProfileId: number): Promise<IStudentAssignment[]> {
        const actionName = 'getAssignmentsForStudent';
        mainStore.startTask(actionName);

        try {
            const getCourseResponse: AxiosResponse<IStudentAssignment[]> = await jwtInterceptor.get('api/studentAssignment', { params: { id: userProfileId } });

            return getCourseResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Assignments for Student', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAssignmentsForLoggedInStudent(): Promise<IAssignmentForStudentView[]> {
        const actionName = 'getAssignmentsForLoggedInStudent';
        mainStore.startTask(actionName);

        try {
            const getCourseResponse: AxiosResponse<IAssignmentForStudentView[]> = await jwtInterceptor.get('api/studentAssignment/student-courses');

            return getCourseResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Assignments for Student', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCourseAssignment(studentId: number, courseId: number): Promise<IStudentAssignment> {
        const actionName = 'getCourseAssignment';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IStudentAssignment> = await jwtInterceptor.get('api/studentAssignment/course-assignment', { params: { studentId, courseId } });

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Course Assignments', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getLessonResult(studentId: number, courseId: number, lessonId: number): Promise<ILessonResult> {
        const actionName = 'getLessonResult';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ILessonResult> = await jwtInterceptor.get('api/studentAssignment/lesson-result', { params: { studentId, courseId, lessonId } });

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Lesson Assignment results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getLessonAssignments(studentId: number, courseId: number): Promise<ISimpleLessonAssignment[]> {
        const actionName = 'getCourseLessons';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleLessonAssignment[]> = await jwtInterceptor.get('api/studentAssignment/course-lessons', { params: { studentId, courseId } });

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Lesson Assignments', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCourseAssignment(courseAssignment: UpdateCourseAssignment): Promise<void> {
        const actionName = 'updateCourseAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/course-update', courseAssignment);

            mainStore.setInfoMsg('Successfully Updated Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateLessonResult(lessonResult: IUpdateLessonResult): Promise<void> {
        const actionName = 'updateLessonResult';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/lesson-result-update', lessonResult);

            mainStore.setInfoMsg('Successfully Updated Lesson Result');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Lesson Assignment', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCourseDeleteStatus(studentId: number, courseId: number): Promise<boolean> {
        const actionName = 'getCourseDeleteStatus';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<boolean> = await jwtInterceptor.get('api/studentAssignment/delete-available', { params: { studentId, courseId } });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to check if delete is available for this Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function dropAssignment(studentId: number, courseId: number): Promise<void> {
        const actionName = 'dropAssignment';
        mainStore.startTask(actionName);

        try {
            const mdl = {
                studentId,
                courseId
            };

            await jwtInterceptor.post('api/studentAssignment/drop', mdl);

            mainStore.setInfoMsg('Successfully dropped Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to drop Course Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function deleteAssignment(studentId: number, courseId: number): Promise<void> {
        const actionName = 'deleteCourse';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.delete('api/studentAssignment/delete', { params: { studentId, courseId } });

            mainStore.setInfoMsg('Successfully deleted Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to delete Course Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function validateCourseAssignments(studentAssignments: IAddStudentAssignment[]): Promise<IAddStudentAssignment[]> {
        const actionName = 'validateCourseAssignments';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IAddStudentAssignment[]> = await jwtInterceptor.post('api/studentAssignment/validate', studentAssignments);

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Validate Assignment(s)', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function validateOrganizationAssignments(organizationAssignments: IOrganizationAssignment[]):  Promise<IOrganizationAssignment[]> {
        const actionName = 'validateOrganizationAssignments';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IOrganizationAssignment[]> = await jwtInterceptor.post('api/studentAssignment/validate-org-assignments', organizationAssignments);

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Validate Assignment(s)', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAssignedCoursesForStudents(studentIds: string[]): Promise<ISimpleCourse[]> {
        const actionName = 'getAssignedCoursesForStudents';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleCourse[]> = await jwtInterceptor.post('api/studentAssignment/assigned-courses-for-students', studentIds);

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch assignments', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAssignedCoursesForOrganization(organizationLevel: OrganizationLevel, organizationId: number): Promise<ISimpleCourse[]> {
        const actionName = 'getAssignedCoursesForOrganization';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleCourse[]> = await jwtInterceptor.get('api/studentAssignment/assigned-courses-for-organization', { params: { organizationLevel, organizationId } });

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch assignments', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAssignedCoursesOrganizationWide(organizationLevel: OrganizationLevel, organizationId: number, orgWideName: string): Promise<ISimpleCourse[]> {
        const actionName = 'getAssignedCoursesOrganizationWide';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleCourse[]> = await jwtInterceptor.get('api/studentAssignment/assigned-courses-organization-wide', { params: { organizationLevel, organizationId, orgWideName } });

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch assignments', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getStudentAssignmentDropCount(studentDropMdl: DropStudentAssignment) : Promise<number> {
        const actionName = 'getStudentAssignmentDropCount';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<number> = await jwtInterceptor.post('api/studentAssignment/individual-assignment-drop-count', studentDropMdl);

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch student drop count', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getOrganizationAssignmentDropCount(organizationDropMdl: DropOrganizationAssignment) : Promise<number> {
        const actionName = 'getOrganizationAssignmentDropCount';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<number> = await jwtInterceptor.post('api/studentAssignment/organization-assignment-drop-count', organizationDropMdl);

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to organization fetch drop count', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getOrganizationWideAssignmentDropCount(organizationWideDropMdl: DropOrganizationWideAssignment) : Promise<number> {
        const actionName = 'getOrganizationWideAssignmentDropCount';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<number> = await jwtInterceptor.post('api/studentAssignment/organization-wide-assignment-drop-count', organizationWideDropMdl);

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch organization wide drop count', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function dropAssignmentForIndividuals(studentDropMdl: DropStudentAssignment): Promise<void> {
        const actionName = 'dropAssignmentForIndividuals';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/drop-assignment-for-students', studentDropMdl);

            mainStore.setInfoMsg('Successfully dropped Student Assignment(s)');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to drop student assignment(s)', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function dropAssignmentForOrganization(organizationDropMdl: DropOrganizationAssignment): Promise<void> {
        const actionName = 'dropAssignmentForOrganization';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/drop-assignment-for-organization', organizationDropMdl);

            mainStore.setInfoMsg('Successfully dropped assignment(s) for organization');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to drop assignment(s) for organization', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function dropAssignmentOrganizationWide(organizationWideDropMdl: DropOrganizationWideAssignment): Promise<void> {
        const actionName = 'dropAssignmentOrganizationWide';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/drop-assignment-organization-wide', organizationWideDropMdl);

            mainStore.setInfoMsg('Successfully dropped organization wide assignment(s)');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to drop organization wide assignment(s)', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAssignmentPreferences(divisionId: number): Promise<IAssignmentPreferences[]> {
        const actionName = 'getAssignmentPreferences';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IAssignmentPreferences[]> = await jwtInterceptor.get('api/studentAssignment/assignment-preferences', {params: { divisionId }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Assignment Preferences', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateAssignmentPreferences(assignmentPreferences: IUpdateAssignmentPreferences): Promise<void> {
        const actionName = 'updateAssignmentPreferences';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/update-assignment-preference', assignmentPreferences);

            mainStore.setInfoMsg('Successfully Updated Course Assignment Preference');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course Assignment Preference', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function addAssignmentPreferences(assignmentPreferences: INewAssignmentPreferences): Promise<void> {
        const actionName = 'addAssignmentPreferences';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/studentAssignment/add-assignment-preference', assignmentPreferences);

            mainStore.setInfoMsg('Successfully Added Course Assignment Preference');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Add Course Assignment Preference', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchNewHireAssignments(filter: IGridFilter): Promise<INewHireAssignmentSearchResult> {
        const actionName = 'searchNewHireAssignments';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);

            const response: AxiosResponse<INewHireAssignmentSearchResult> = await jwtInterceptor.get('api/new-hire-assignment/search', { params: { filter: btoa(filterJson) }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return New Hire Assignment search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function addNewHireAssignment(newHireAssignment: IAddNewHireAssignment): Promise<void> {
        const actionName = 'addNewHireAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/new-hire-assignment/add', newHireAssignment);

            mainStore.setInfoMsg('Successfully added New Hire Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Add New Hire Course Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getNewHireAssignment(newHireAssignmentId: number): Promise<INewHireCourseAssignment> {
        const actionName = 'getNewHireAssignment';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<INewHireCourseAssignment> = await jwtInterceptor.get('api/new-hire-assignment', { params: { newHireAssignmentId }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch New Hire Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function deleteNewHireAssignment(newHireAssignmentId: number): Promise<void>  {
        const actionName = 'deleteNewHireAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.delete('api/new-hire-assignment/delete', { params: { newHireAssignmentId } });

            mainStore.setInfoMsg('Successfully deleted New Hire Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to delete New Hire Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateNewHireAssignmentOrganizations(updateNewHireOrgs: IUpdateNewHireAssignmentOrganization): Promise<void> {
        const actionName = 'updateNewHireAssignmentOrganizations';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/new-hire-assignment/update-organizations', updateNewHireOrgs);

            mainStore.setInfoMsg('Successfully updated New Hire Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update New Hire Assignment Organization', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateNewHireAssignmentCourse(updateCourse: IUpdateNewHireAssignmentCourse): Promise<void> {
        const actionName = 'updateNewHireAssignmentCourse';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/new-hire-assignment/update-course', updateCourse);

            mainStore.setInfoMsg('Successfully updated New Hire Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update New Hire Assignment Course', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateNewHireCourseAssignmentDetails(updateDetails: IUpdateNewHireAssignmentDetails): Promise<void> {
        const actionName = 'updateNewHireCourseAssignmentDetails';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/new-hire-assignment/update-details', updateDetails);

            mainStore.setInfoMsg('Successfully updated New Hire Course Assignment Details');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update New Hire Assignment Details', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchAnnualAssignments(filter: IGridFilter): Promise<IAnnualAssignmentSearchResult> {
        const actionName = 'searchAnnualAssignments';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);

            const response: AxiosResponse<IAnnualAssignmentSearchResult> = await jwtInterceptor.get('api/annual-assignment/search', { params: { filter: btoa(filterJson) }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Annual Assignment search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAnnualAssignment(annualAssignmentId: number): Promise<IAnnualCourseAssignment> {
        const actionName = 'getAnnualAssignment';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IAnnualCourseAssignment> = await jwtInterceptor.get('api/annual-assignment', { params: { annualAssignmentId }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Annual Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function deleteAnnualAssignment(annualAssignmentId: number): Promise<void>  {
        const actionName = 'deleteAnnualAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.delete('api/annual-assignment/delete', { params: { annualAssignmentId } });

            mainStore.setInfoMsg('Successfully deleted Annual Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to delete Annual Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateAnnualAssignmentOrganizations(updateAnnualOrgs: IUpdateAnnualAssignmentOrganization): Promise<void> {
        const actionName = 'updateAnnualAssignmentOrganizations';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/annual-assignment/update-organizations', updateAnnualOrgs);

            mainStore.setInfoMsg('Successfully updated Annual Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Annual Course Assignment Organization', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateAnnualAssignmentCourse(updateCourse: IUpdateAnnualAssignmentCourse): Promise<void> {
        const actionName = 'updateAnnualAssignmentCourse';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/annual-assignment/update-course', updateCourse);

            mainStore.setInfoMsg('Successfully updated Annual Assignment');
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Annual Course Assignment Course', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateAnnualCourseAssignmentDetails(updateDetails: IUpdateAnnualAssignmentDetails): Promise<void> {
        const actionName = 'updateAnnualCourseAssignmentDetails';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/annual-assignment/update-details', updateDetails);

            mainStore.setInfoMsg('Successfully updated Annual Assignment Details');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Annual Assignment Details', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function addAnnualAssignment(annualAssignment: IAddAnnualAssignment): Promise<void> {
        const actionName = 'addAnnualAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/annual-assignment/add', annualAssignment);

            mainStore.setInfoMsg('Successfully added Annual Course Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Add Annual Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchRollingAssignments(filter: IGridFilter): Promise<IRollingAssignmentSearchResult> {
        const actionName = 'searchRollingAssignments';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);

            const response: AxiosResponse<IRollingAssignmentSearchResult> = await jwtInterceptor.get('api/rolling-assignment/search', { params: { filter: btoa(filterJson) }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Rolling Assignment search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getRollingAssignment(rollingAssignmentId: number): Promise<IRollingCourseAssignment> {
        const actionName = 'getRollingAssignment';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IRollingCourseAssignment> = await jwtInterceptor.get('api/rolling-assignment', { params: { rollingAssignmentId }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Rolling Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function deleteRollingAssignment(rollingAssignmentId: number): Promise<void> {
        const actionName = 'deleteRollingAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.delete('api/rolling-assignment/delete', { params: { rollingAssignmentId } });

            mainStore.setInfoMsg('Successfully deleted Rolling Assignment');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to delete Rolling Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateRollingAssignmentOrganizations(updateRollingOrgs: IUpdateRollingAssignmentOrganization): Promise<void> {
        const actionName = 'updateRollingAssignmentOrganizations';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/rolling-assignment/update-organizations', updateRollingOrgs);

            mainStore.setInfoMsg('Successfully updated Rolling Assignment Organizations');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Rolling Assignment Organizations', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateRollingAssignmentCourse(updateCourse: IUpdateRollingAssignmentCourse): Promise<void> {
        const actionName = 'updateRollingAssignmentCourse';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/rolling-assignment/update-course', updateCourse);

            mainStore.setInfoMsg('Successfully updated Rolling Assignment Course');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Rolling Assignment Course', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateRollingCourseAssignmentDetails(updateDetails: IUpdateRollingAssignmentDetails): Promise<void> {
        const actionName = 'updateRollingCourseAssignmentDetails';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/rolling-assignment/update-details', updateDetails);

            mainStore.setInfoMsg('Successfully updated Rolling Assignment Details');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Rolling Assignment Details', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function addRollingAssignment(rollingAssignment: IAddRollingAssignment): Promise<void> {
        const actionName = 'addRollingAssignment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/rolling-assignment/add', rollingAssignment);

            mainStore.setInfoMsg('Successfully added Rolling Course Assignment');
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Add Rolling Assignment', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    function $reset(): void {
        assignmentState.value = { selectedCourses: [] };
    }

    return {
        displayNamesOfAssignedStudents,
        displayNamesOfAssignedCourses,
        getAllCourses,
        getEnableDate,
        getTargetDate,
        getDropDate,
        getCourseAssignmentState,
        getSelectedOrganizationDisplayString,

        getStudentDisplayNameById,
        getCourseNameById,
        setIndividualStudents,
        setOrganizationStudents,
        setCourses,
        setAssignedDates,
        removeStudent,
        removeOrganizationAssignment,
        removeCourse,
        addStudentAssignments,
        addAssignmentToOrganization,
        getAssignmentsForStudent,
        getAssignmentsForLoggedInStudent,
        getCourseAssignment,
        getLessonResult,
        getLessonAssignments,
        updateCourseAssignment,
        updateLessonResult,
        getCourseDeleteStatus,
        dropAssignment,
        deleteAssignment,
        validateCourseAssignments,
        getAssignedCoursesForStudents,
        getAssignedCoursesForOrganization,
        getAssignedCoursesOrganizationWide,
        getStudentAssignmentDropCount,
        getOrganizationAssignmentDropCount,
        getOrganizationWideAssignmentDropCount,
        dropAssignmentForIndividuals,
        dropAssignmentForOrganization,
        dropAssignmentOrganizationWide,
        validateOrganizationAssignments,
        getAssignmentPreferences,
        updateAssignmentPreferences,
        addAssignmentPreferences,
        searchNewHireAssignments,
        addNewHireAssignment,
        getNewHireAssignment,
        deleteNewHireAssignment,
        updateNewHireAssignmentOrganizations,
        updateNewHireAssignmentCourse,
        updateNewHireCourseAssignmentDetails,
        searchAnnualAssignments,
        getAnnualAssignment,
        deleteAnnualAssignment,
        updateAnnualAssignmentOrganizations,
        updateAnnualAssignmentCourse,
        updateAnnualCourseAssignmentDetails,
        addAnnualAssignment,
        searchRollingAssignments,
        getRollingAssignment,
        deleteRollingAssignment,
        updateRollingAssignmentOrganizations,
        updateRollingAssignmentCourse,
        updateRollingCourseAssignmentDetails,
        addRollingAssignment,
        $reset
    }
})

export default useCourseAssignmentStore;
