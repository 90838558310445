import jwtInterceptor from '@/helpers/http';
import * as jwtHelper from '@/helpers/jwtHelper';
import {formatServerErrorResponse} from '@/helpers/jwtHelper';
import useMainStore from './main.store';
import useAssignmentStore from '@/store/student/assignments.store';
import useAccountStore from '@/store/account.store';
import useStudentAccountStore from '@/store/student/student.account.store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import { useLocalStorage } from '@vueuse/core';
import {computed, ComputedRef, Ref} from 'vue';
import {defineStore} from 'pinia';
import LoginResult from '@/models/auth/loginResult';
import UserPermissions from '@/models/user/UserPermissions';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import HealthcareAcademyPermissions from '@/models/auth/HealthcareAcademyPermissions';
import ITokenResult from '@/models/auth/ITokenResult';
import PromoteUserRole from '@/models/auth/PromoteUserRole';

export interface IAuthStore {
    isAdmin: ComputedRef<boolean>,
    isActivelyLoggedIn: ComputedRef<boolean>,
    getUserToken: ComputedRef<string>,
    getRefreshToken: ComputedRef<string>,
    getIdentityId: ComputedRef<string>,
    getUserProfileId: ComputedRef<number | undefined>,
    getStudentProfileId: ComputedRef<number | undefined>,
    getFirstName: ComputedRef<string>,
    getLastName: ComputedRef<string>,
    getUsername: ComputedRef<string>,
    getEmail: ComputedRef<string>,
    hasAdminChosenToSkipSyncProfile: ComputedRef<boolean>,
    getAuthorizationLevel: ComputedRef<AuthorizationLevel>,
    hasImportPermission: ComputedRef<boolean>,
    hasAnyAddEditPermission: ComputedRef<boolean>,
    hasAddEditPermissionForStudentAndAdmin: ComputedRef<boolean>,
    hasCourseAssignmentPermission: ComputedRef<boolean>,
    hasAutomatedAssignmentPermission: ComputedRef<boolean>,
    hasDropAssignmentPermission: ComputedRef<boolean>,
    hasAnyCompetencyEvaluatorPermission: ComputedRef<boolean>,
    getLoggedInUsersHcaPermissions: ComputedRef<HealthcareAcademyPermissions[]>,

    login(username: string, password: string): Promise<{ syncStudentProfile: boolean, loginMeetsStandard: boolean } | void>,
    studentLogin(loginUserProfileId: number, username: string, password: string): Promise<{ loginMeetsStandard: boolean } | void>,
    updatePassword(userIdentityId: string, currentPassword: string, password: string, confirmPassword: string): Promise<void>,
    resetPassword(resetEmail: string, password: string, confirmPassword: string, token: string): Promise<void>,
    logout(): Promise<void>,
    samlLogout(): Promise<any>,
    redirectAADLogin(msiToken: string): Promise<void>,
    isAuthenticated(): Promise<void>,
    forgotPassword(forgotPasswordEmail: string): Promise<void>,
    adminResetPassword(userId: number, password: string, confirmPassword: string): Promise<void>,
    updateUserPermissions(permissions: UserPermissions): Promise<void>,
    getUserPermissions(userId: number): Promise<UserPermissions | void>,
    updateLoginInfo(loginUserProfileId: number, currentPassword: string, updateEmail: string, newPassword: string, confirmPassword: string): Promise<void>,
    promoteUserRole(promotionMdl: PromoteUserRole): Promise<void>,
    confirmEmail(emailToConfirm: string, token: string): Promise<void>,
    doesLoginMeetRequirements(identityId: string): Promise<{ loginMeetsStandard: boolean } | void>,
    saveTokenData(accessToken: string, refreshToken: string): void,
    adminChoseToSkipSyncProfile(): void,
    $reset(): void,
}

const http = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL ?? window.location.origin });

const useAuthStore = defineStore('authStore', (): IAuthStore => {
    const mainStore = useMainStore();

    const token: Ref<string> = useLocalStorage('authStore_accessToken', '');
    const refreshToken: Ref<string> = useLocalStorage('authStore_refreshToken', '');
    const skipStudentSync: Ref<Map<string, boolean>> = useLocalStorage('authStore_hasAdminChosenToSkipSyncProfile', new Map<string, boolean>());

    const isAdmin: ComputedRef<boolean> = computed<boolean>(() => jwtHelper.determineAuthorizationLevel(token.value) >= AuthorizationLevel.DepartmentAdministrator);
    const isActivelyLoggedIn: ComputedRef<boolean> = computed<boolean>(() => !jwtHelper.isTokenExpired(token.value));
    const getUserToken: ComputedRef<string> = computed<string>(() => token.value);
    const getRefreshToken: ComputedRef<string> = computed<string>(() => refreshToken.value);
    const getIdentityId: ComputedRef<string> = computed<string>(() => jwtHelper.determineIdentityId(token.value));
    const getUserProfileId: ComputedRef<number | undefined> = computed<number | undefined>(() => jwtHelper.determineUserProfileId(token.value));
    const getStudentProfileId: ComputedRef<number | undefined> = computed<number | undefined>(() => jwtHelper.determineStudentProfileId(token.value));
    const getFirstName: ComputedRef<string> = computed<string>(() => jwtHelper.determineFirstName(token.value));
    const getLastName: ComputedRef<string> = computed<string>(() => jwtHelper.determineLastName(token.value));
    const getUsername: ComputedRef<string> = computed<string>(() => jwtHelper.determineUsername(token.value));
    const getEmail: ComputedRef<string> = computed<string>(() => jwtHelper.determineEmail(token.value));
    const getAuthorizationLevel: ComputedRef<AuthorizationLevel> = computed<AuthorizationLevel>(() => jwtHelper.determineAuthorizationLevel(token.value));
    const hasAdminChosenToSkipSyncProfile: ComputedRef<boolean> = computed<boolean>(() => skipStudentSync.value.get( getUsername.value ) ?? false);
    const getLoggedInUsersHcaPermissions: ComputedRef<HealthcareAcademyPermissions[]> = computed<HealthcareAcademyPermissions[]>(() => jwtHelper.determineHcaPermissions(token.value).map(p => p as HealthcareAcademyPermissions));

    const hasImportPermission: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        return getLoggedInUsersHcaPermissions.value.includes(HealthcareAcademyPermissions.AllowStudentImport);
    });
    const hasAnyAddEditPermission: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        const currentPerms: string[] = getLoggedInUsersHcaPermissions.value;
        const allEditUserPerms: HealthcareAcademyPermissions[] = [HealthcareAcademyPermissions.ManageAdmins, HealthcareAcademyPermissions.ManageStudents];

        if (Array.isArray(currentPerms)){
            return currentPerms.some(perm =>  allEditUserPerms.includes(perm as HealthcareAcademyPermissions))
        }

        return allEditUserPerms.includes(currentPerms);
    });
    const hasAddEditPermissionForStudentAndAdmin: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        const currentPerms = getLoggedInUsersHcaPermissions.value;

        return currentPerms.includes(HealthcareAcademyPermissions.ManageStudents) && currentPerms.includes(HealthcareAcademyPermissions.ManageAdmins);
    });
    const hasCourseAssignmentPermission: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        return getLoggedInUsersHcaPermissions.value.includes(HealthcareAcademyPermissions.AllowCourseAssign);
    });
    const hasAutomatedAssignmentPermission: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        return getLoggedInUsersHcaPermissions.value.includes(HealthcareAcademyPermissions.ManageAutomatedAssignments);
    });
    const hasDropAssignmentPermission: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        return getLoggedInUsersHcaPermissions.value.includes(HealthcareAcademyPermissions.DropCourse);
    });
    const hasAnyCompetencyEvaluatorPermission: ComputedRef<boolean> = computed<boolean>(() => {
        if (jwtHelper.determineAuthorizationLevel(token.value) === AuthorizationLevel.SystemAdministrator)
            return true;

        const currentPerms: string[] = getLoggedInUsersHcaPermissions.value;
        const competencyPermissions: HealthcareAcademyPermissions[] = [HealthcareAcademyPermissions.ManageCompetencyEvaluator, HealthcareAcademyPermissions.CreateCustomCompetencies];

        if (Array.isArray(currentPerms)){
            return currentPerms.some(perm =>  competencyPermissions.includes(perm as HealthcareAcademyPermissions))
        }

        return competencyPermissions.includes(currentPerms);
    });

    async function login(username: string, password: string): Promise<{ syncStudentProfile: boolean, loginMeetsStandard: boolean } | void> {
        const actionName = 'login';
        mainStore.startTask(actionName);
        mainStore.clearMessages();

        try {
            const loginResponse: AxiosResponse<LoginResult> = await http.post('api/auth/login', { username, password });

            if (loginResponse.data.errorMessage && !loginResponse.data.token)
                return mainStore.setErrorMsg(loginResponse.data.errorMessage);

            if (!loginResponse.data.token || !loginResponse.data.refreshToken || loginResponse.data.loginMeetsStandard === undefined)
                return mainStore.setErrorMsg('Unable to login. Please try again later.');

            saveTokenData(loginResponse.data.token, loginResponse.data.refreshToken);

            return {
                syncStudentProfile: !loginResponse.data.hasStudentProfile,
                loginMeetsStandard: loginResponse.data.loginMeetsStandard
            }
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Error occurred on Login', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function studentLogin(loginUserProfileId: number, username: string, password: string): Promise<{ loginMeetsStandard: boolean } | void> {
        const actionName: string = 'studentLogin';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<{ loginMeetsStandard: boolean }> = await jwtInterceptor.post('api/auth/student-login', { userProfileId: loginUserProfileId, username, password });

            return { loginMeetsStandard: response.data.loginMeetsStandard };
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Sync profiles. Please notify your administrator', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updatePassword(userIdentityId: string, currentPassword: string, password: string, confirmPassword: string): Promise<void> {
        const actionName: string = 'updatePassword';
        mainStore.startTask(actionName);

        try {
            const mdl = { identityId: userIdentityId, currentPassword, password, confirmPassword };

            await jwtInterceptor.put('api/account/user/update-password', mdl);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Update Password, Please Contact your Administrator', error);

            mainStore.setErrorMsg(errorMsg);

            throw new Error(errorMsg.toString());
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function resetPassword(resetEmail: string, password: string, confirmPassword: string, token: string): Promise<void> {
        const actionName: string = 'resetPassword';
        mainStore.startTask(actionName);

        try {
            const mdl = { email: resetEmail, password, confirmPassword, token };

            await http.post('api/auth/reset-password-url', mdl);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to reset your password. Please contact your Administrator', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function logout(): Promise<void> {
        try {
            await jwtInterceptor.post('api/auth/logout');
        }
        finally {
            const assignmentStore = useAssignmentStore();
            const accountStore = useAccountStore();
            const studentAccountStore = useStudentAccountStore();

            $reset();
            assignmentStore.$reset();
            accountStore.$reset();
            studentAccountStore.$reset();
        }
    }

    async function samlLogout(): Promise<any> {
        try {
            return await jwtInterceptor.post('api/auth/adfs/logout');
        }
        finally {
            const assignmentStore = useAssignmentStore();
            const accountStore = useAccountStore();
            const studentAccountStore = useStudentAccountStore();

            $reset();
            assignmentStore.$reset();
            accountStore.$reset();
            studentAccountStore.$reset();
        }
    }

    async function redirectAADLogin(msiToken: string): Promise<void> {
        const actionName: string = 'redirectAADLogin';
        mainStore.startTask(actionName);

        try {
            const redirectLoginResponse: AxiosResponse<ITokenResult> = await http.post('api/auth/loginAAD', { msiToken });

            saveTokenData(redirectLoginResponse.data.accessToken, redirectLoginResponse.data.refreshToken);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('We were unable to log you in. Please try again later.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function isAuthenticated(): Promise<void> {
        const actionName: string = 'isAuthenticated';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.get('api/auth/ping');
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Sorry, we were unable validate you being logged in.', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function forgotPassword(forgotPasswordEmail: string): Promise<void> {
        const actionName: string = 'forgotPassword';
        mainStore.startTask(actionName);

        try {
            await http.post('api/auth/forgot-password', { email: forgotPasswordEmail });
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to send you a forgotten password link.  Please try again later. ', error)

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function adminResetPassword(userId: number, password: string, confirmPassword: string): Promise<void> {
        const actionName: string = 'adminResetPassword';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/auth/admin/reset-password', { userId, password, confirmPassword });

            mainStore.setSuccessMsg('Password Updated Successfully');
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to reset password', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateUserPermissions(permissions: UserPermissions): Promise<void> {
        const actionName: string = 'updateUserPermissions';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/auth/update-permissions', permissions);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update User Permissions', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getUserPermissions(userId: number): Promise<UserPermissions> {
        const actionName: string = 'getUserPermissions';
        mainStore.startTask(actionName);

        try {
            const getUserPermsResponse: AxiosResponse<UserPermissions> = await jwtInterceptor.get('api/auth/user-claims', { params: { userId }});

            return getUserPermsResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch User permissions', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateLoginInfo(loginUserProfileId: number, currentPassword: string, updateEmail: string, newPassword: string, confirmPassword: string): Promise<void> {
        const actionName: string = 'updateLoginInfo';
        mainStore.startTask(actionName);

        try {
            const updateLoginInfoResponse: AxiosResponse<ITokenResult> = await jwtInterceptor.post('api/auth/update-login-info', {
                userProfileId: loginUserProfileId,
                currentPassword,
                email: updateEmail,
                newPassword,
                confirmPassword
            });

            saveTokenData(updateLoginInfoResponse.data.accessToken, updateLoginInfoResponse.data.refreshToken);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update login Info. Please try again later.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function promoteUserRole(promotionMdl: PromoteUserRole): Promise<void> {
        const actionName: string = 'promoteUserRole';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/auth/promote-user-role', promotionMdl);

            mainStore.setSuccessMsg('User promoted successfully');
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to promote user. Please try again later.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function confirmEmail(emailToConfirm: string, token: string): Promise<void> {
        const actionName: string = 'confirmEmail';
        mainStore.startTask(actionName);

        try {
            await http.post('api/auth/confirm-email', { email: emailToConfirm, token })

        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to confirm email address. Please contact your Administrator', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function doesLoginMeetRequirements(identityId: string): Promise<{ loginMeetsStandard: boolean } | void> {
        const actionName: string = 'doesLoginMeetRequirements';
        mainStore.startTask(actionName);

        try {
            const doesLoginMeetRequirementsResponse: AxiosResponse<{ loginMeetsStandard: boolean }> = await jwtInterceptor.get('api/auth/meet-login-standard', { params: { identityId }});

            return { loginMeetsStandard: doesLoginMeetRequirementsResponse.data.loginMeetsStandard };
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to validate login standard', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    function saveTokenData(newAccessToken: string, newRefreshToken: string): void {
        token.value = newAccessToken;
        refreshToken.value = newRefreshToken;
    }

    function adminChoseToSkipSyncProfile(): void {
        skipStudentSync.value.set(getUsername.value, true);
    }

    function $reset(): void {
        token.value = '';
        refreshToken.value = '';
    }

    return {
        isAdmin,
        isActivelyLoggedIn,
        getIdentityId,
        getUserProfileId,
        getStudentProfileId,
        getUserToken,
        getRefreshToken,
        getFirstName,
        getLastName,
        getUsername,
        getEmail,
        hasAdminChosenToSkipSyncProfile,
        getAuthorizationLevel,
        hasImportPermission,
        hasAnyAddEditPermission,
        hasAddEditPermissionForStudentAndAdmin,
        hasCourseAssignmentPermission,
        hasAutomatedAssignmentPermission,
        hasDropAssignmentPermission,
        getLoggedInUsersHcaPermissions,
        hasAnyCompetencyEvaluatorPermission,

        login,
        studentLogin,
        updatePassword,
        resetPassword,
        logout,
        samlLogout,
        redirectAADLogin,
        isAuthenticated,
        forgotPassword,
        adminResetPassword,
        updateUserPermissions,
        getUserPermissions,
        updateLoginInfo,
        promoteUserRole,
        confirmEmail,
        doesLoginMeetRequirements,
        saveTokenData,
        adminChoseToSkipSyncProfile,
        $reset
    }
});

export default useAuthStore;
