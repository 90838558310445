<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import IMenuStyle from '@/models/courses/IMenuStyle';
import ICourseInterface from '@/models/courses/Course/ICourseInterface';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import * as zod from 'zod';

const courseStore = useCourseStore();
const mainStore = useMainStore();

courseStore.$onAction(({ after, name }) => {
    after(async () => {
        if (['getCourse'].includes(name)) {
            await initializeValues();
        }
    })
})

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            courseInterface: zod.string().optional().nullable(),
            menuStyleId: zod.number().int().optional(),
            screenWidth: zod.number().int().positive(),
            screenHeight: zod.number().int().positive(),
            maxIncompleteLessonsAllowed: zod.number().int().nonnegative().optional(),
            browseType: zod.string().optional().nullable(),
            reviewType: zod.string().optional().nullable(),
        })
    )
});

const { value: courseInterface } = useField<string>('courseInterface');
const { value: menuStyleId } = useField<number>('menuStyleId');
const { value: screenWidth } = useField<number>('screenWidth');
const { value: screenHeight } = useField<number>('screenHeight');
const { value: maxIncompleteLessonsAllowed } = useField<number>('maxIncompleteLessonsAllowed');
const { value: browseType } = useField<string>('browseType');
const { value: reviewType } = useField<string>('reviewType');

const allCourseInterfaces: { interfaceValue: string, name: string }[] = [
    { interfaceValue: 'AiccCompliant' , name: 'AICC'},
    { interfaceValue: 'NoInterface' , name: 'No Interface'}
];
const browseTypes: { browseValue: string, browseLabel: string }[] = [
    { browseValue: 'All', browseLabel: 'All' },
    { browseValue: 'None', browseLabel: 'None' }
];
const reviewTypes: { browseValue: string, browseLabel: string }[] = [
    { browseValue: 'All', browseLabel: 'All' },
    { browseValue: 'None', browseLabel: 'None' }
];
const allMenuStyles = ref<IMenuStyle[]>([]);

onMounted(async() => await initializeValues());

async function initializeValues() {
    const course = courseStore.getActiveCourse;

    if (!course?.courseId)
        return;

    resetForm({
        values: {
            menuStyleId: course.menuStyleId,
            courseInterface: course.courseInterface,
            browseType: course.browseType,
            reviewType: course.reviewType,
            maxIncompleteLessonsAllowed: course.maxIncompleteLessonsAllowed,
            screenWidth: course.screenWidth,
            screenHeight: course.screenHeight,
        }
    });

    try {
        allMenuStyles.value = await courseStore.getAllMenuStyles();
    } catch (e) {
        allMenuStyles.value = [];
        console.error('Error fetching menu styles', e);
    }
}

const onSubmit = handleSubmit(async (values) => {
    const courseId = courseStore.getActiveCourseId;

    if (!courseId)
        return mainStore.setErrorMsg('No active course id found');

    const {
        courseInterface,
        menuStyleId,
        screenWidth,
        screenHeight,
        maxIncompleteLessonsAllowed,
        browseType,
        reviewType
    } = values;

    const updateCourseMdl: ICourseInterface = {
        courseId: courseId,
        courseInterface: courseInterface,
        menuStyleId,
        screenWidth,
        screenHeight,
        maxIncompleteLessonsAllowed,
        browseType: browseType,
        reviewType: reviewType
    };

    await courseStore.updateCourseInterface(updateCourseMdl);
});

</script>

<template>
    <div class="border-round-lg" style="background-color: var(--hca-form-bg);">
        
        <form @submit="onSubmit" class="px-3">
            <div class="flex flex-column py-3 gap-5">

                <div class="flex flex-column md:flex-row md:justify-content-start gap-5 md:gap-3 mt-5">

                    <div>
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="courseType"
                                v-model="courseInterface"
                                :options="allCourseInterfaces"
                                option-value="interfaceValue"
                                option-label="name"
                                class="w-full"
                            />
                            <label for="courseType">Course Type</label>
                        </div>
                        <small class="p-error"> {{ errors["courseInterface"] }}</small>
                    </div>

                    <div>
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="menuStyle"
                                v-model="menuStyleId"
                                :options="allMenuStyles"
                                option-value="menuStyleId"
                                option-label="name"
                                class="w-full"
                            />
                            <label for="menuStyle">Menu Style</label>
                        </div>
                        <small class="p-error"> {{ errors["menuStyleId"] }}</small>
                    </div>

                </div>

                <div class="flex flex-column md:flex-row md:justify-content-start gap-5 md:gap-3">

                    <div style="min-width: 190px">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="browseType"
                                v-model="browseType"
                                :options="browseTypes"
                                option-value='browseLabel'
                                option-label='browseValue'
                                class="w-full"
                            />
                            <label for="browseType">AICC Browse Type</label>
                        </div>
                        <small class="p-error"> {{ errors["browseType"] }}</small>
                    </div>

                    <div style="min-width: 190px">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="reviewType"
                                v-model="reviewType"
                                :options="reviewTypes"
                                option-value='browseLabel'
                                option-label='browseValue'
                                class="w-full"
                            />
                            <label for="reviewType">AICC Review Type</label>
                        </div>
                        <small class="p-error"> {{ errors["reviewType"] }}</small>
                    </div>

                </div>

                <div class="flex flex-column md:flex-row md:justify-content-start gap-5 md:gap-3">

                    <div>
                        <Skeleton v-if="mainStore.isBusy" width="10rem" height="2rem"/>
                        <span v-else class="p-float-label w-full">
                            <InputNumber
                                v-model="screenWidth"
                                id="screenWidth"
                                class="w-full"
                            />
                            <label for="screenWidth">Screen Width</label>
                        </span>
                        <small class="p-error"> {{ errors["screenWidth"] }}</small>
                    </div>

                    <div>
                        <Skeleton v-if="mainStore.isBusy" width="10rem" height="2rem"/>
                        <span v-else class="p-float-label" style="min-width:235px;">
                            <InputNumber
                                v-model="screenHeight"
                                id="screenHeight"
                                class="w-full"
                            />
                            <label for="screenHeight">Screen Height</label>
                        </span>
                        <small class="p-error"> {{ errors["screenHeight"] }}</small>
                    </div>

                    <div>
                        <Skeleton v-if="mainStore.isBusy" width="10rem" height="2rem"/>
                        <span v-else class="p-float-label">
                            <InputNumber
                                v-model="maxIncompleteLessonsAllowed"
                                id="maxIncompleteOpenLessons"
                                class="w-full"
                            />
                            <label for="maxIncompleteOpenLessons">Max Incomplete Lessons Open</label>
                        </span>
                        <small class="p-error"> {{ errors["maxIncompleteLessonsAllowed"] }}</small>
                    </div>

                </div>

                <div class="flex flex-row justify-content-center md:justify-content-end">
                    <Button
                        label="Save" 
                        type="submit" 
                        :loading="mainStore.isBusy"
                    />                
                </div>
                
            </div>
        </form>
    </div>
</template>
