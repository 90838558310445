<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import useAccountStore from '@/store/account.store';
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import useOrganizationStore from '@/store/organization.store';
import UserOrgLevels from '@/models/user/OrganizationLevel/IUserOrgLevels';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import ISimpleCorporation from '@/models/user/OrganizationLevel/Corporation/ISimpleCorporation';
import ISimpleRegion from '@/models/user/OrganizationLevel/Region/ISimpleRegion';
import ISimpleDivision from '@/models/user/OrganizationLevel/Division/ISimpleDivision';
import ISimpleFacility from '@/models/user/OrganizationLevel/Facility/ISimpleFacility';
import ISimpleDepartment from '@/models/user/OrganizationLevel/Department/ISimpleDepartment';
import IOrganizationHierarchy from '@/models/user/OrganizationLevel/IOrganizationHierarchy';

const mainStore = useMainStore();
const accountStore = useAccountStore();
const organizationStore = useOrganizationStore();
const authStore = useAuthStore();

const corporationId = ref<number | undefined>();
const regionId = ref<number | undefined>();
const divisionId = ref<number | undefined>();
const facilityId = ref<number | undefined>();
const departmentId = ref<number | undefined>();

const corporations = ref<ISimpleCorporation[]>([]);
const regions = ref<ISimpleRegion[]>([]);
const divisions = ref<ISimpleDivision[]>([]);
const facilities = ref<ISimpleFacility[]>([]);
const departments = ref<ISimpleDepartment[]>([]);

const isCorporationLoading = ref<boolean>(false);
const isRegionLoading = ref<boolean>(false);
const isDivisionLoading = ref<boolean>(false);
const isFacilityLoading = ref<boolean>(false);
const isDepartmentLoading = ref<boolean>(false);

const isRegionDisabled = ref<boolean>(true);
const isDivisionDisabled = ref<boolean>(true);
const isFacilityDisabled = ref<boolean>(true);
const isDepartmentDisabled = ref<boolean>(true);

const shouldShowCorporation = computed<boolean>(() => authStore.getAuthorizationLevel == AuthorizationLevel.SystemAdministrator);
const shouldShowRegion = computed<boolean>(() => authStore.getAuthorizationLevel > AuthorizationLevel.RegionAdministrator);
const shouldShowDivision = computed<boolean>(() => authStore.getAuthorizationLevel > AuthorizationLevel.DivisionAdministrator);
const shouldShowFacility = computed<boolean>(() => authStore.getAuthorizationLevel > AuthorizationLevel.FacilityAdministrator);
const shouldShowDepartment = computed<boolean>(() => authStore.getAuthorizationLevel > AuthorizationLevel.DepartmentAdministrator);

const orgHierarchy = computed<IOrganizationHierarchy>(() => ({
    corporation: accountStore.getActiveUserProfile.corporation ?? {} as ISimpleCorporation,
    region: accountStore.getActiveUserProfile.region ?? {} as ISimpleRegion,
    division: accountStore.getActiveUserProfile.division ?? {} as ISimpleDivision,
    facility: accountStore.getActiveUserProfile.facility ?? {} as ISimpleFacility,
    department: accountStore.getActiveUserProfile.department ?? {} as ISimpleDepartment
} as IOrganizationHierarchy))

onMounted(() => initializeValues());

async function getRegionsByCorporationId(corporationId: number | undefined): Promise<void> {
    isRegionDisabled.value = (authStore.getAuthorizationLevel < AuthorizationLevel.RegionAdministrator);

    if (!corporationId) {
        isRegionDisabled.value = true;
        regions.value = [];
        regionId.value = undefined;
        return;
    }

    isRegionLoading.value = true;
    regionId.value = undefined;

    await organizationStore.getRegionsByCorporation(corporationId)
    .then((allRegions) => regions.value = allRegions ?? [])
    .finally(() => isRegionLoading.value = false);
}

async function getDivisionsByRegionId(regionId: number | undefined): Promise<void> {
    isDivisionDisabled.value = (authStore.getAuthorizationLevel < AuthorizationLevel.DivisionAdministrator);

    if (!regionId) {
        isDivisionDisabled.value = true;
        divisions.value = [];
        divisionId.value = undefined;
        return;
    }

    isDivisionLoading.value = true;
    divisionId.value = undefined;

    await organizationStore.getDivisionsByRegion(regionId)
    .then((allDivisions) => divisions.value = allDivisions)
    .finally(() => isDivisionLoading.value = false);
}

async function getFacilitiesByDivisionId(divisionId: number | undefined): Promise<void> {
    isFacilityDisabled.value = (authStore.getAuthorizationLevel < AuthorizationLevel.FacilityAdministrator);

    if (!divisionId) {
        isFacilityDisabled.value = true;
        facilities.value = [];
        facilityId.value = undefined;
        return;
    }

    isFacilityLoading.value = true;
    facilityId.value = undefined;

    await organizationStore.getFacilitiesByDivision(divisionId)
    .then((allFacilities) => facilities.value = allFacilities)
    .finally(() => isFacilityLoading.value = false);
}

async function getDepartmentsByFacilityId(facilityId: number | undefined): Promise<void> {
    isDepartmentDisabled.value = (authStore.getAuthorizationLevel < AuthorizationLevel.DepartmentAdministrator);

    if (!facilityId) {
        isDepartmentDisabled.value = true;
        departments.value = [];
        departmentId.value = undefined;
        return;
    }

    isDepartmentLoading.value = true;
    departmentId.value = undefined;

    await organizationStore.getDepartmentsByFacility(facilityId)
    .then((allDepartments) => departments.value = allDepartments)
    .finally(() => isDepartmentLoading.value = false);
}

const initializeValues = (async () => {
    const adminLevel = authStore.getAuthorizationLevel;

    corporations.value = [orgHierarchy.value!.corporation];
    corporationId.value = orgHierarchy.value?.corporation?.corporationId;

    regions.value = [orgHierarchy.value!.region];
    regionId.value = orgHierarchy.value?.region?.regionId;
    isRegionDisabled.value = false;

    divisions.value = [orgHierarchy.value!.division]
    divisionId.value = orgHierarchy.value?.division?.divisionId;
    isDivisionDisabled.value = false;

    facilities.value = [orgHierarchy.value!.facility];
    facilityId.value = orgHierarchy.value?.facility?.facilityId;
    isFacilityDisabled.value = false;

    departments.value = [orgHierarchy.value!.department]
    departmentId.value = orgHierarchy.value?.department?.departmentId;
    isDepartmentDisabled.value = false;


    if (adminLevel < AuthorizationLevel.FacilityAdministrator)
        return;

    if (adminLevel < AuthorizationLevel.DivisionAdministrator) {
        await getDepartmentsByFacilityId(facilityId.value);
        departmentId.value = orgHierarchy.value?.department?.departmentId;
        return;
    }

    if (adminLevel < AuthorizationLevel.RegionAdministrator) {
        await getFacilitiesByDivisionId(divisionId.value);
        facilityId.value = orgHierarchy.value?.facility?.facilityId;
        await getDepartmentsByFacilityId(facilityId.value);
        departmentId.value = orgHierarchy.value?.department?.departmentId;
        return;
    }

    if (adminLevel < AuthorizationLevel.CorporationAdministrator) {
        await getDivisionsByRegionId(regionId.value);
        divisionId.value = orgHierarchy.value?.division?.divisionId;
        await getFacilitiesByDivisionId(divisionId.value);
        facilityId.value = orgHierarchy.value?.facility?.facilityId;
        await getDepartmentsByFacilityId(facilityId.value);
        departmentId.value = orgHierarchy.value?.department?.departmentId;
        return;
    }

    if (adminLevel < AuthorizationLevel.SystemAdministrator) {
        await getRegionsByCorporationId(corporationId.value);
        regionId.value = orgHierarchy.value?.region?.regionId;
        await getDivisionsByRegionId(regionId.value);
        divisionId.value = orgHierarchy.value?.division?.divisionId;
        await getFacilitiesByDivisionId(divisionId.value);
        facilityId.value = orgHierarchy.value?.facility?.facilityId;
        await getDepartmentsByFacilityId(facilityId.value);
        departmentId.value = orgHierarchy.value?.department?.departmentId;
        return;
    }

    await GetCorporations();
    regionId.value = orgHierarchy.value?.region?.regionId;
    await getRegionsByCorporationId(corporationId.value);
    regionId.value = orgHierarchy.value?.region?.regionId;
    await getDivisionsByRegionId(regionId.value);
    divisionId.value = orgHierarchy.value?.division?.divisionId;
    await getFacilitiesByDivisionId(divisionId.value);
    facilityId.value = orgHierarchy.value?.facility?.facilityId;
    await getDepartmentsByFacilityId(facilityId.value);
    departmentId.value = orgHierarchy.value?.department?.departmentId;
});

async function GetCorporations(): Promise<void> {
    isCorporationLoading.value = true;

    await organizationStore.getAllCorporations()
    .then((allCorporations) => corporations.value = allCorporations ?? [])
    .finally(() => isCorporationLoading.value = false);
}

const updateUserOrgLevels = (async () => {
    const userProfileId = accountStore.getActiveUserProfileId;

    if (!userProfileId)
        return mainStore.setErrorMsg('User Id not found, please refresh page.');

    const updateOrgMdl: UserOrgLevels = {
        userProfileId: userProfileId,
        corporationId: corporationId.value,
        regionId: regionId.value,
        divisionId: divisionId.value,
        facilityId: facilityId.value,
        departmentId: departmentId.value
    }

    await accountStore.updateUserOrgLevels(updateOrgMdl);

});

</script>

<template>
    <div class="border-round-lg p-3" style="background-color: var(--hca-form-bg);">
        <form class="flex justify-content-center" @submit.prevent="updateUserOrgLevels">
            <div class="flex flex-column" style="min-width: 260px">

                <div class="mt-5" v-if="shouldShowCorporation">
                    <div class="p-float-label">
                        <Dropdown
                            v-model.lazy="corporationId"
                            input-id="level1Selector"
                            class="w-full"
                            option-label="name"
                            option-value="corporationId"
                            placeholder="Select a Corporation"
                            :options="corporations"
                            :loading="isCorporationLoading"
                            @change="getRegionsByCorporationId(corporationId)"
                        />
                        <label for="level1Selector">Corporation</label>
                    </div>
                </div>

                <div class="mt-5" v-if="shouldShowRegion">
                    <div class="p-float-label">
                        <Dropdown
                            v-model="regionId"
                            inputId="region"
                            class="w-full"
                            option-label="name"
                            option-value="regionId"
                            placeholder="Select a Region"
                            :options="regions"
                            :disabled="isRegionDisabled"
                            :loading="isRegionLoading"
                            @change="getDivisionsByRegionId(regionId)"
                        />
                        <label for="region">Region</label>
                    </div>
                </div>

                <div class="mt-5" v-if="shouldShowDivision">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="divisionId"
                            inputId="division"
                            class="w-full"
                            option-label="name"
                            option-value="divisionId"
                            placeholder="Select a Division"
                            :options="divisions"
                            :disabled="isDivisionDisabled"
                            :loading="isDivisionLoading"
                            @change="getFacilitiesByDivisionId"
                        />
                        <label for="division">Division</label>
                    </span>
                </div>

                <div class="mt-5" v-if="shouldShowFacility">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="facilityId"
                            inputId="facility"
                            class="w-full"
                            option-label="name"
                            option-value="facilityId"
                            placeholder="Select a Facility"
                            :options="facilities"
                            :disabled="isFacilityDisabled"
                            :loading="isFacilityLoading"
                            @change="getDepartmentsByFacilityId(facilityId)"
                        />
                        <label for="facility">Facility</label>
                    </span>
                </div>

                <div class="mt-5" v-if="shouldShowDepartment">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="departmentId"
                            inputId="department"
                            class="w-full"
                            option-label="name"
                            option-value="departmentId"
                            placeholder="Select a Department"
                            :options="departments"
                            :disabled="isDepartmentDisabled"
                            :loading="isDepartmentLoading"
                        />
                        <label for="department">Department</label>
                    </span>
                </div>

                <div class="flex flex-row justify-content-center mt-5">
                    <Button
                        label="Save"
                        type="submit"
                        :loading="mainStore.isBusy"
                    />
                </div>

            </div>

        </form>

    </div>
</template>
