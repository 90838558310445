<script setup lang="ts">
import router from '@/router';
import useMainStore from "@/store/main.store";
import {ref, watch} from 'vue';
import {MenuItem} from "primevue/menuitem";

const mainStore = useMainStore();

const visibleMenu = ref<boolean>(false);
const competencyMenuItems = ref<MenuItem[]>([
    {
        label: 'Competencies',
        icon: 'resources-icon',
        command: () => router.push('/evaluator-menu'),
    },
    {
        label: 'Evaluation Results',
        icon: 'resources-icon',
        command: () => router.push('/competency/results'),
    },
    {
        label: 'Custom Competencies',
        icon: 'pi pi-pencil',
        items: [
            {
                label: 'Active',
                class: 'font-medium',
                command: () => router.push('/competency/custom/active')
            },
            {
                label: 'Retired',
                class: 'font-medium',
                 command: () => router.push('/competency/custom/retired')
            },
            {
                label: 'Create New',
                class: 'font-medium',
                command: () => mainStore.setInfoMsg('Coming soon...')
            }
        ]
    }
]);

watch(router.currentRoute, (toPath, fromPath) => {
    if(toPath.fullPath !== fromPath.fullPath) 
        visibleMenu.value = false;
});

</script>

<template>
    <div class="flex flex-column -mb-2 min-h-screen">
        <TopMenuComponent />
        <div class="flex flex-column md:flex-row mt-0 mx-0 flex-grow-1">

            <div style="background: var(--hca-gold);" class="mx-0">

                <div class="flex justify-content-end md:hidden">
                    <Button
                        icon="pi pi-bars text-2xl"
                        class="mr-5"
                        plain
                        text
                        @click="visibleMenu = true"
                    />
                </div>

                <div class="hidden md:flex p-0 ml-2" style="min-width: 250px;">
                    <AdminMenu :menu-items="competencyMenuItems" />
                </div>

            </div>

            <slot />

        </div>

        <FooterComponent />
        <Sidebar v-model:visible="visibleMenu" position="full" style="background: var(--hca-gold);">
            <AdminMenu :menu-items="competencyMenuItems" />
        </Sidebar>
    </div>
</template>
