<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import {parseNumericQueryParam} from "@/helpers/queryParamHelper";
import LessonInterface from '@/models/courses/Lesson/LessonInterface';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import {useRoute} from "vue-router";
import {computed} from "vue";
import * as zod from 'zod';

const courseStore = useCourseStore();
const mainStore = useMainStore();
const route = useRoute();

courseStore.$onAction(({ after, name }) => {
    after(() => {
        if (name === 'getLesson') {
            initializeValues();
        }
})});

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            lessonInterface: zod.string().optional().nullable(),
            masteryScore: zod.number().int().nonnegative().optional().nullable(),
            maxScore: zod.number().int().nonnegative().optional().nullable(),
            timeLimitAction: zod.string().optional().nullable(),
            timeLimit: zod.number().int().nonnegative().optional().nullable(),
            auPassword: zod.string().optional().nullable(),
            additionalParams: zod.string().optional().nullable(),
            cmiLaunchData: zod.string().optional().nullable(),
        })
    )
});

const { value: lessonInterface } = useField<string>('lessonInterface');
const { value: masteryScore } = useField<number | undefined>('masteryScore');
const { value: maxScore } = useField<number | undefined>('maxScore');
const { value: timeLimitAction } = useField<string | undefined>('timeLimitAction');
const { value: timeLimit } = useField<number>('timeLimit');
const { value: auPassword } = useField<string | undefined>('auPassword');
const { value: additionalParams } = useField<string | undefined>('additionalParams');
const { value: cmiLaunchData } = useField<string | undefined>('cmiLaunchData');

const allLessonTypes: { interfaceValue: string , name: string }[] = [
    { interfaceValue: 'AICC' , name: 'AICC'},
    { interfaceValue: 'NoInterface' , name: 'No Interface'}
];
const allTimeLimitActions: { value: string , label: string }[] = [
    { value: 'continue,no message' , label: 'Continue w/NoMessage'},
    { value: 'exit,message' , label: 'Exit w/Message'},
    { value: 'no action' , label: 'No Action'}
];

const lessonId = computed<number | undefined>(() => parseNumericQueryParam(route?.query?.lessonId));

function initializeValues() {
    const lesson = courseStore.getActiveLesson;

    if (!lesson) return;

    console.log(lesson.lessonInterface);

    resetForm({
        values: {
            lessonInterface: lesson.lessonInterface,
            masteryScore: lesson.masteryScore,
            maxScore: lesson.maxAllowedScore,
            timeLimitAction: lesson.timeLimitAction,
            timeLimit: !lesson.timeLimitInSeconds ? 0 : (lesson.timeLimitInSeconds / 60),
            auPassword: lesson.auPassword,
            additionalParams: lesson.parameters,
            cmiLaunchData: lesson.coreVendor,
        }
    });
}

const onSubmit = handleSubmit(async (values: any) => {
    const {
        lessonInterface,
        screenWidth,
        screenHeight,
        masteryScore,
        maxScore,
        timeLimitAction,
        timeLimit,
        auPassword,
        additionalParams,
        cmiLaunchData
    } = values;

    if (!lessonId.value)
        return mainStore.setErrorMsg('Lesson not found. Please try again later');

    const updatedLesson: LessonInterface = {
        lessonId: lessonId.value,
        lessonInterface, 
        screenWidth, 
        screenHeight, 
        masteryScore,
        maxAllowedScore: maxScore,
        timeLimitAction,
        timeLimitInSeconds: (timeLimit * 60),
        auPassword,
        parameters: additionalParams,
        coreVendor: cmiLaunchData,
    };

    await courseStore.updateLessonInterface(updatedLesson);
});

</script>

<template>
    <div class="border-round-lg mb-3" style="background-color: var(--hca-form-bg);">

        <form class="px-3" @submit="onSubmit" >
            <div class="flex flex-column py-3">
                
                <div class="flex mt-3 md:mt-5">
                    <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                    <div v-else class="p-float-label" style="min-width: 160px;">
                        <Dropdown 
                            inputId="lessonInterface"
                            v-model="lessonInterface"
                            :options="allLessonTypes"
                            option-value="interfaceValue"
                            option-label="name"
                            class="w-full min-w-10rem"
                            style="min-width:180px"
                            show-clear
                        />
                        <label for="lessonInterface">Lesson Interface</label>
                    </div>
                    <small class="p-error"> {{ errors["lessonInterface"] }}</small>
                </div>

                <div class="flex md:flex-row md:mt-5 w-full justify-content-center md:justify-content-start">

                    <div class="mr-3 mt-5 md:mt-0">
                        <Skeleton v-if="mainStore.isBusy" width="10rem" height="2rem"/>
                        <span v-else class="p-float-label">
                            <InputNumber 
                                id="masteryScore" 
                                v-model="masteryScore"
                                input-class="w-7rem"
                                style="min-width: 140px"
                                :min="0"
                                :max="100"
                            />
                            <label for="masteryScore">Mastery Score</label>
                        </span>
                        <small class="p-error"> {{ errors["masteryScore"] }}</small>
                    </div>

                    <div class="mr-3 mt-5 md:mt-0">
                        <Skeleton v-if="mainStore.isBusy" width="10rem" height="2rem"/>
                        <span v-else class="p-float-label">
                            <InputNumber 
                                id="maxScore"
                                v-model="maxScore"
                                input-class="w-5rem"
                                style="min-width: 110px"
                                :min="0"
                                :max="100"
                            />
                            <label for="maxScore">Max Score</label>
                        </span>
                        <small class="p-error"> {{ errors["maxScore"] }}</small>
                    </div>

                    <div class="mr-3 mt-5 md:mt-0">
                        <Skeleton v-if="mainStore.isBusy" width="10rem" height="2rem" />
                        <span v-else class="p-float-label">
                            <InputNumber 
                                id="timeLimit"
                                v-model="timeLimit" 
                                input-class="w-10rem"
                                style="min-width: 215px"
                            />
                            <label for="timeLimit">Time Limit (In Minutes)</label>
                        </span>
                        <small class="p-error"> {{ errors["timeLimit"] }}</small>
                    </div>

                </div>
                    
                <div class="mt-5 w-full">
                    <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                    <div v-else class="p-float-label">
                        <Dropdown 
                            inputId="timeLimitAction"
                            v-model="timeLimitAction"
                            :options="allTimeLimitActions"
                            option-value="value"
                            option-label="label"
                            class="w-full"
                        />
                        <label for="timeLimitAction">Allow re-entry of completed Lesson</label>
                    </div>
                    <small class="p-error"> {{ errors["timeLimitAction"] }}</small>
                </div>
                            
                <div class="mt-5">
                    <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                    <span v-else class="p-float-label">
                        <InputText id="auPassword" v-model="auPassword" class="w-full"/>
                        <label for="auPassword">Active Unit Password</label>
                    </span>
                    <small class="p-error"> {{ errors["auPassword"] }}</small>
                </div>

                <div class="flex flex-column mt-5 w-full">
                    <Skeleton v-if="mainStore.isBusy" width="100%" height="8rem"/>
                    <span v-else class="p-float-label w-full">
                        <Textarea v-model="additionalParams" autoResize rows="5" cols="30" class="w-full"/>
                        <label>Additional Parameters</label>
                    </span>
                    <small class="p-error"> {{ errors["additionalParams"] }}</small>
                </div>
                
                <div class="flex flex-column mt-5 w-full">
                    <Skeleton v-if="mainStore.isBusy" width="100%" height="8rem"/>
                    <span v-else class="p-float-label w-full">
                        <Textarea v-model="cmiLaunchData" autoResize rows="5" cols="30" class="w-full"/>
                        <label>Core Vendor</label>
                    </span>
                    <small class="p-error"> {{ errors["cmiLaunchData"] }}</small>
                </div>

                <div class="flex flex-row justify-content-center md:justify-content-end mt-5">
                    <Button
                        label="Save" 
                        type="submit" 
                        :loading="mainStore.isBusy"
                    />                
                </div>
                
            </div>
        </form>

    </div>
</template>
